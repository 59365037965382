import React, { useRef, useEffect, useState } from "react";
import { ArrowDropUpIcon, CloseIcon, QuestionMarkIcon } from "./Icons";

export default function ArticleDetails({ item, status, onClose }) {
  const detailsRef = useRef(null);
  const buttonRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (status === "open" && detailsRef.current) {
      setTimeout(() => {
        buttonRef.current?.classList.add("!opacity-100");
        detailsRef.current?.classList.add("border-t-2");
        detailsRef.current?.classList.add("!h-fit");
      }, 150);
    } else if (status === "close") {
      buttonRef.current?.classList.remove("!opacity-100");
      detailsRef.current?.classList.remove("border-t-2");
    }
  }, [status]);

  const handleQuestionMarkClick = () => {
    setShowPopup(!showPopup);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div
      id="article-details"
      ref={detailsRef}
      className={`pb-4 bg-[var(--bg-color)] animate-drawer-${status} border-x-2 border-[var(--text-color)] flex`}
    >
      <div className="flex flex-col self-center tablet:flex-row tablet:justify-evenly p-4 text-sm">
        <div className="w-full tablet:w-1/4 pb-2 tablet:pb-0 tablet:pr-2 flex flex-col justify-evenly">
          <h4 className="font-bold">{item.headline}</h4>
          <a
            href={item.url}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Article
          </a>
        </div>
        <div className="w-full tablet:w-3/4 bg-[var(--surface-color)] p-4 rounded-lg">
          <div className="flex gap-2">
            <p className="text-left font-semibold">Summary</p>
            <button type="button" class="h-5 w-5">
              <QuestionMarkIcon onClick={handleQuestionMarkClick} />
            </button>
          </div>
          {showPopup && (
            <div className="popup top-6 absolute bg-[var(--accent-color)] p-2 text-sm mr-4 rounded-lg text-justify flex flex-col gap-1">
              <p className="font-semibold">Summary</p>
              <p className="text-xs">
                The summary is generated by Google's Gemini AI. It is meant to
                be a brief idea of what the article is about without taking away
                from the original author.
              </p>
              <p className="italic text-xs">
                Note: AI can be unpredictable. The details of the summary may
                not be accurate. Please see the full article for accurate
                information.
              </p>
              <button
                onClick={handlePopupClose}
                className="absolute right-1 top-1 h-6 w-6"
                type="button"
              >
                <CloseIcon />
              </button>
            </div>
          )}
          <p className="text-justify">{item.summary}</p>
        </div>
      </div>
      <button
        className={`h-4 absolute bottom-0 left-0 right-0 bg-[var(--text-color)] text-[var(--bg-color)] flex justify-center items-center opacity-0`}
        onClick={onClose}
        ref={buttonRef}
        type="button"
      >
        <div className="w-5 h-5">
          <ArrowDropUpIcon />
        </div>
      </button>
    </div>
  );
}
